import React from "react";
import sam from "../images/sam.jpg";
import alex from "../images/alex.png";
import github from "../images/github.svg";
import personal from "../images/personal.svg";
import twitter from "../images/twitter.svg";
import { Element } from "react-scroll";

const About = () => {

  const personClass = "flex flex-col items-center max-w-sm px-6 mx-auto";
  const imgClass = "rounded-full w-36 h-36 border-4 border-white sm:w-44 sm:h-44 lg:w-56 lg:h-56";
  const nameClass = "text-purple-3 font-medium text-lg sm:text-xl lg:text-2xl";
  const fieldClass = "text-sm text-gray-4 opacity-80 mb-2 lg:text-base";
  const linksClass = "grid grid-cols-3 w-20 gap-1.5 mb-4 sm:w-24";
  const bioClass = "text-sm lg:text-base";

  return (
    <section className="text-center">
      <Element name="about" className="relative sm:-top-16 md:-top-20" />
      <div className="bg-gradient-to-b from-labtab-2 to-purple-1 text-white pt-12 px-8 sm:py-16 md:text-left">
        <h2 className="font-medium text-lg mb-2 sm:text-2xl sm:mb-3 md:mx-auto md:text-3xl md:max-w-4xl md:w-full">Who are we?</h2>
        <div className="text-xs max-w-xs mx-auto leading-relaxed px-6 sm:text-sm sm:max-w-sm md:max-w-4xl md:px-0 md:w-full md:pr-48 md:text-lg">
          Goodwright was founded to create performant, high quality biological software.
          We are a team of bioinformaticians, software developers and designers who are passionate about creating software that helps scientists and researchers to do their work more effectively.
          Our team have PhDs in Structural Bioinformatics, Machine Learning, Neuroscience and Computational Biology.
          We have a combined experience of over 40 years in software development and bioinformatics.
          We're growing quickly, and are always looking for new talent to join our team.
        </div>
      </div>
    </section>
  );
};

About.propTypes = {
  
};

export default About;