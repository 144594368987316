import React from "react";
import { Element } from "react-scroll";
import flow from "../images/flow.svg";
import execution from "../images/flowscreenshot.webp";
import scale from "../images/scale.svg";
import pipeline from "../images/pipeline.svg";
import peka from "../images/PEKA.png";
import labtab from "../images/labtab.svg";
import labtabHome from "../images/labtab.png";
import working from "../images/working.svg";
import projects from "../images/projects.png";
import notes from "../images/notes.png";

const Projects = () => {

  const headerClass = "max-w-lg mx-auto mt-16 mb-6 sm:mb-10 lg:max-w-5xl lg:text-left xl:max-w-6xl"
  const logoClass = "mx-auto mb-3 sm:mb-5 lg:mx-0";
  const introClass = "text-sm font-light mx-auto sm:text-base lg:max-w-xl lg:mx-0";

  const overviewClass = "mb-4 lg:flex lg:max-w-5xl mx-auto lg:gap-12 xl:max-w-6xl";
  const screenshotClass = "rounded shadow max-w-xs w-full mb-8 sm:max-w-lg mx-auto sm:mb-8 lg:mx-0 lg:max-w-xl";
  const pointsClass = "text-sm grid gap-3 font-light w-64 mx-auto max-w-xs mb-8 sm:max-w-sm sm:w-auto sm:text-lg lg:text-left lg:max-w-none";
  const linkClass = "block rounded text-sm py-2.5 w-44 mx-auto cursor-pointer lg:mx-0";
  
  const rowClass = "flex flex-col gap-y-16 justify-between grid-x-10 mt-16 mx-auto sm:gap-y-20 md:flex-row md:max-w-4xl md:mt-24 lg:max-w-6xl lg:mt-30 xl:max-w-7xl";
  const colClass = "w-full max-w-sm mx-auto md:w-52 md:max-w-none lg:w-72 xl:w-80";
  const imgClass = "h-32 sm:h-40 md:h-32 lg:h-36 xl:h-48 mx-auto object-contain"
  const colHeadingClass = "font-medium text-lg mt-4 mb-1 sm:text-2xl md:text-base lg:text-lg xl:text-2xl";
  const colText = "text-sm font-light sm:text-base md:text-xs lg:text-sm xl:text-base";

  const bridgeClass = "text-base font-light w-48 mx-auto sm:text-xl sm:w-64 lg:text-2xl lg:w-72";

  return (
    <section className="text-white text-center relative -top-36 -mb-36 lg:top-0 lg:mb-0">
      <Element name="software" />
      <div
        className="bg-purple-1 h-36 relative border-b border-purple-1"
        style={{clipPath: "polygon(0 100%, 100% 0%, 100% 100%)"}}
      />
      <div className="bg-purple-1 pt-8 pb-10 px-6 sm:pb-16 lg:px-12">
        <h2 className="text-xl mb-2 font-medium lg:text-2xl">Our Platforms and Tools</h2>
        <div className="text-sm font-light max-w-xs mx-auto border-b border-white pb-6">
          We work with labs and institutes across the UK to develop
          production-grade research platforms
        </div>
        <div className={headerClass}>
          <img src={flow} alt="Flow" className={`${logoClass} w-24 sm:w-32`} />
          <div className={introClass}>
            Flow is an end-to-end platform for managing your entire biodata ecosystem - from the pipelines that generate the data, to the sharing, collating and visualising of the final results.
          </div>
        </div>
        <div className={overviewClass}>
          <img src={execution} alt="Execution" className={screenshotClass} />
          <div>
            <div className={pointsClass}>
              <div>Structures your company or institute's biology data</div>
              <div>Uses  Nextflow, and other pipeline technologies</div>
              <div>Hosting, serving and analysing hundreds of terabytes of data</div>
            </div>
            <a
              href="https://flow.bio"
              className={`${linkClass} bg-blue-500`}
            >Try the platform</a>
          </div>

        </div>
        <div className={rowClass}>
          <div className={colClass}>
            <img src={scale} className={imgClass} alt="" />
            <h3 className={colHeadingClass}>
              Upload data of any size
            </h3>
            <div className={colText}>
              Flow handles next-gen sequencing data with ease,
              and with powerful, customisable privacy settings for all your data,
              you can keep your data private to you or your group,
              or share it widely.
            </div>
          </div>
          <div className={colClass}>
            <img src={pipeline} className={imgClass} alt="" />
            <h3 className={colHeadingClass}>Run Bioinformatics pipelines</h3>
            <div className={colText}>
              Flow is built on top of Nextflow and offers a suite of open source,
              verified, chainable Nextflow pipelines for data analysis. You can
              analyse your own data, or process it in the context of other
              datasets and genomes.
            </div>
          </div>
          <div className={colClass}>
            <img src={peka} className={imgClass} alt="" />
            <h3 className={colHeadingClass}>Explore data interactively</h3>
            <div className={colText}>
              All process outputs and logs are as accessible and viewable as if
              you had run them locally. Flow also allows you to explore your
              data with dynamic, interactive visualisations.
            </div>
          </div>
        </div>
      </div>


    </section>
  );
};

Projects.propTypes = {
  
};

export default Projects;