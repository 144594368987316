import React from "react";
import { Element } from "react-scroll";
import work from "../images/work.svg";
import centres from "../images/centres.png";
import centresRow from "../images/centres-row.png";
import dri from "../images/dri.svg";
import marylyon from "../images/mary-lyon.svg";
import kcl from "../images/kcl.png";
import sidb from "../images/sidb.png";
import nottingham from "../images/nottingham.png";
import edinburgh from "../images/edinburgh.png";
import crick from "../images/crick.png";
import ucl from "../images/ucl.png";
import swc from "../images/swc.png";
import imperial from "../images/imperial.png";

const WhyGW = () => {

  const h3Class = "text-purple-4 font-medium text-base mb-2 sm:text-lg lg:text-xl lg:mb-4";
  const textClass = "text-xs font-light max-w-xs mx-auto sm:text-sm sm:max-w-sm";

  return (
    <section className="text-center py-10 px-6 sm:py-16 relative z-20 sm:px-8 md:px-10 lg:py-24">
      <Element name="why" className="relative sm:-top-24 md:-top-30" />
      <h2 className="text-purple-3 text-xl font-semibold sm:text-2xl lg:text-3xl">Our Clients</h2>
      


      <section class="bg-white text-gray-800 pt-8 pb-4 -mx-6 sm:-mx-8 md:-mx-10">
        <p class="text-center text-lg font-extralight leading-8 ">We are trusted by the UK's most innovative centres and institutes.</p>
        
        <div class="logos group relative overflow-hidden whitespace-nowrap  py-10 [mask-image:_linear-gradient(to_right,_transparent_0,_white_128px,white_calc(100%-128px),_transparent_100%)]">
          <div class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max">
            <img class="mx-4 sm:mx-8 inline h-16" src={sidb} alt="SIDB" />
            <img class="mx-4 sm:mx-8 inline h-14" src={kcl} alt="KCL" />
            <img class="mx-4 sm:mx-8 inline h-16" src={nottingham} alt="Nottingham" />
            <img class="mx-4 sm:mx-8 inline h-10" src={dri} alt="DRI" />
            <img class="mx-4 sm:mx-8 inline h-14" src={marylyon} alt="MaryLyon" />
            <img class="mx-4 sm:mx-8 inline h-20" src={edinburgh} alt="Edinburgh" />
            <img class="mx-4 sm:mx-8 inline h-24" src={crick} alt="Crick" />
            <img class="mx-4 sm:mx-8 inline h-11" src={ucl} alt="UCL" />
            <img class="mx-4 sm:mx-8 inline h-14" src={swc} alt="SWC" />
            <img class="mx-4 sm:mx-8 inline h-16" src={imperial} alt="Imperial" />
          </div>

          <div class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max">
            <img class="mx-4 sm:mx-8 inline h-16" src={sidb} alt="SIDB" />
            <img class="mx-4 sm:mx-8 inline h-14" src={kcl} alt="KCL" />
            <img class="mx-4 sm:mx-8 inline h-16" src={nottingham} alt="Nottingham" />
            <img class="mx-4 sm:mx-8 inline h-10" src={dri} alt="DRI" />
            <img class="mx-4 sm:mx-8 inline h-14" src={marylyon} alt="MaryLyon" />
            <img class="mx-4 sm:mx-8 inline h-20" src={edinburgh} alt="Edinburgh" />
            <img class="mx-4 sm:mx-8 inline h-24" src={crick} alt="Crick" />
            <img class="mx-4 sm:mx-8 inline h-11" src={ucl} alt="UCL" />
            <img class="mx-4 sm:mx-8 inline h-14" src={swc} alt="SWC" />
            <img class="mx-4 sm:mx-8 inline h-16" src={imperial} alt="Imperial" />
          </div>
        </div>
      </section>
    </section>
  );
};

WhyGW.propTypes = {
  
};

export default WhyGW;