import React, { useState } from "react";
import { Element } from "react-scroll";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {

  const [name, setName] = useState("");
  const [institution, setInstitution] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const submit = e => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }
    setLoading(true);
    fetch("/contact/", {
      method: "POST",
      body: new URLSearchParams({name, institution, subject, email, message, recaptcha: recaptchaValue})
    }).then(resp => resp.json()).then(json => {
      setLoading(false);
      if (json.success) {
        setShowSuccess(true);
        setShowError(false);
        setName("");
        setInstitution("");
        setSubject("");
        setEmail("");
        setMessage("");
        setRecaptchaValue(null);
      } else {
        setShowError(true);
        setShowSuccess(false);
      }
    })
  }

  const inputClass = "bg-gray-1 block w-full rounded outline-none px-3 py-2 text-sm font-light placeholder-gray-2 sm:text-base sm:py-2.5 lg:py-3.5";
  const boxClass = "px-4 py-3 rounded relative text-xs md:text-sm flex-grow flex justify-center items-center";

  return (
    <section className="px-6 py-10 sm:py-24">
      <Element name="contact" />
      <h2 className="font-semibold text-lg text-purple-3 text-center mb-1 sm:max-w-2xl sm:mx-auto sm:text-left sm:text-xl lg:max-w-4xl lg:text-2xl">Contact Us</h2>
      <div className="text-sm mb-5 text-center sm:text-base sm:max-w-2xl sm:mx-auto sm:text-left sm:pr-48 lg:max-w-4xl lg:pr-96">
        We're always open to new clients. We're keen to explore ideas with you,
        whatever your needs are.
      </div>
      <form className={`grid gap-2 sm:grid-cols-[1fr_2fr] sm:w-full sm:max-w-2xl sm:mx-auto sm:gap-3 lg:max-w-4xl ${loading ? "pointer-events-none opacity-70" : ""}`} onSubmit={submit}>
        <div className="grid gap-2 sm:gap-3 lg:gap-3.5">
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Name"
            required
            className={inputClass}
          />
          <input
            value={institution}
            onChange={e => setInstitution(e.target.value)}
            placeholder="Institution (optional)"
            className={inputClass}
          />
          <input
            value={subject}
            onChange={e => setSubject(e.target.value)}
            placeholder="Subject"
            required
            className={inputClass}
          />
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            required
            type="email"
            className={inputClass}
          />
        </div>
        <div>
          <textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Message"
            required
            className={`${inputClass} resize-none h-32 sm:h-40 lg:h-48`}
          />
          <div className="mt-2 sm:mt-3 lg:mt-3.5 grid sm:flex gap-2 sm:gap-3 lg:gap-3.5">
            <ReCAPTCHA
              sitekey="6LdjKCsqAAAAAF9WZxa4j95B5oWVI1LU67EFuQ1j"
              onChange={value => setRecaptchaValue(value)}
            />
            <button className="bg-[#11ACA1] block w-full text-white rounded text-sm py-2 font-light sm:w-40 sm:py-2.5 lg:py-3.5">
              Send Message
            </button>
            {showSuccess && (
              <div className={`bg-green-100 text-center border border-green-400 text-green-700 ${boxClass}`}>
                Message sent
              </div>
            )}
            {showError && (
              <div className={`bg-red-100 border-red-400 text-red-700 ${boxClass}`}>
                There was a problem sending.
              </div>
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

Contact.propTypes = {
  
};

export default Contact;