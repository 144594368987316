import React from "react";
import goodwright from "../images/goodwright.svg";

const Footer = () => {
  return (
    <footer className="bg-gray-4 text-white flex flex-col justify-center items-center py-6 gap-1">
      <img src={goodwright} alt="Goodwright" className="w-32 md:w-44" />
      <div className="text-xxs md:text-xs">Company No. 12019252</div>
    </footer>
  );
};

Footer.propTypes = {
  
};

export default Footer;