import React from "react";
import { Element, Link } from "react-scroll";
import scientist from "../images/scientist.svg";

const Header = () => {
  return (
    <header className="relative bg-gradient-to-r from-purple-5 to-purple-4 text-white pt-10 pb-16 px-3 sm:pt-32 sm:pb-20 md:pt-44 md:pb-32 sm:px-6">
      <Element name="top" className="absolute top-0" />
      <div className="md:flex max-w-3xl mx-auto items-center justify-center lg:max-w-4xl">
        <div className="text-center w-64 mx-auto mb-8 sm:w-96 md:text-left lg:w-full lg:max-w-l">
          <h1 className="font-medium text-2xl mb-3 sm:text-4xl md:text-4xl lg:text-5xl lg:mb-5">Software for Scientists</h1>
          <div className="text-base sm:text-lg md:w-96 lg:text-xl">We build tools to accelerate research and expand its reach.</div>
        </div>
        <img src={scientist} alt="Scientist" className="w-48 mx-auto sm:w-56 md:w-64 lg:w-72" />
      </div>
      <div className="absolute -bottom-3 left-0 rounded-full w-full h-7 flex justify-center items-center">
        <Link to="why" smooth={true}>
          <svg className="w-7 h-7 p-1.5 bg-[#4DA0DC] block rounded-full shadow cursor-pointer relative z-40 md:w-10 md:h-10 md:p-2" viewBox="0 0 15 9">
            <path
              fill="white" transform="translate(0, 1)"
              d="M12.9486 0.0561026L7.41797 5.5869L1.84533 0.0141296L0.205078 1.6544L7.376 8.8254L14.5469 1.6544L12.9486 0.0561026Z"
            />
          </svg>
        </Link>
      </div>
    </header>
  );
};

Header.propTypes = {
  
};

export default Header;