import React from "react";
import { Link } from "react-scroll";
import logo from "../images/logo.svg";

const Nav = () => {

  return (
    <nav className="h-20 w-full bg-gradient-to-r from-purple-5 to-purple-4 px-6 items-center z-50 sm:fixed sm:top-0">
      <div className="max-w-7xl w-full mx-auto flex justify-between items-center h-full">
        <Link to="top" smooth={true}><img src={logo} alt="logo" className="h-11 cursor-pointer relative -top-0.5" /></Link>
        <div className="hidden text-white gap-4 cursor-pointer sm:flex md:gap-6">
          <Link to="why" smooth={true}>What We Offer </Link>
          <Link to="software" smooth={true} >Software</Link>
          <Link to="about" smooth={true}>About Us</Link>
          <Link to="contact" smooth={true}>Contact</Link>
        </div>
      </div>
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;